import { Injectable } from '@angular/core';
import { User } from "../models/user-model";

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private readonly currentUser = 'user';

  setUserItem(user: User) {
    localStorage.setItem(this.currentUser, JSON.stringify(user))
    return user;
  }

  getCurrentUser(): string | null {
    return localStorage.getItem(this.currentUser);
  }

  removeAll(): void {
    localStorage.clear()
  }

}
