import {HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HTTP_INTERCEPTORS} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from "../auth/auth.service";

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class InterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    const user = this.authService.currentUserValue;

    // Define URLs or parts of URLs where you do NOT want to include the access token
    const excludedUrls = [
      '/api/v1/auth/merchant/signup',
    ];

    const needsToken = !excludedUrls.some(url => req.url.includes(url));

    if (user != null && needsToken) {
      authReq = req.clone({
        headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + user.accessToken)
      });
    }

    return next.handle(authReq);
  }

}

export const authInterceptorProviders = [
  {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true}
];
