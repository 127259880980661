import {Routes} from '@angular/router';

export const ACCOUNT_ROUTES: Routes = [

  {
    path: 'account',
    loadChildren: () => import('../../account/account.module').then(m => m.AccountModule)
  },

]
