import {Routes} from '@angular/router';

export const AUTH_ROUTES: Routes = [

  {
    path: 'business',
    loadChildren: () => import('../../business/business.module').then(m => m.BusinessModule)
  },

]
