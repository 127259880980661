import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from "ngx-google-analytics";
import { FullCalendarModule } from "@fullcalendar/angular";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { BusinessLayoutComponent } from './layouts/business-layout/business-layout.component';
import { AccountLayoutComponent } from './layouts/account-layout/account-layout.component';

import { SharedModule } from './shared/shared.module';
import { HttpClientModule } from "@angular/common/http";

import * as $ from 'jquery';


@NgModule({
  declarations: [
    AppComponent,
    HomeLayoutComponent,
    BusinessLayoutComponent,
    AccountLayoutComponent,
  ],
  imports: [
    BrowserModule,
    NgxGoogleAnalyticsModule.forRoot('G-3455ZYMBER'),
    NgxGoogleAnalyticsRouterModule,
    FullCalendarModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    SharedModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
