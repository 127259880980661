import {Routes} from '@angular/router';

export const HOME_ROUTES: Routes = [

  {
    path: 'auth',
    loadChildren: () => import('../../auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'home',
    loadChildren: () => import('../../home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'help',
    loadChildren: () => import('../../support/support.module').then(m => m.SupportModule)
  },

]
