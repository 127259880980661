import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AUTH_ROUTES} from './shared/routes/auth-routes.routes';
import {BusinessLayoutComponent} from './layouts/business-layout/business-layout.component';
import {HomeLayoutComponent} from './layouts/home-layout/home-layout.component';
import {HOME_ROUTES} from './shared/routes/home-routes.routes';
import {AuthGuard} from './auth/auth.guard';
import {AuthBusinessGuard} from "./auth/auth-business.guard";
import {AccountLayoutComponent} from "./layouts/account-layout/account-layout.component";
import {ACCOUNT_ROUTES} from "./shared/routes/account-routes.routes";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AccountLayoutComponent,
    data: {title: 'Account Views'},
    children: ACCOUNT_ROUTES,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: BusinessLayoutComponent,
    data: {title: 'Business Views'},
    children: AUTH_ROUTES,
    canActivate: [AuthGuard, AuthBusinessGuard]
  },
  {
    path: '',
    component: HomeLayoutComponent,
    data: {title: 'Home Views'},
    children: HOME_ROUTES
  },
  {path: '**', redirectTo: 'home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
