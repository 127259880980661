import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavbarComponent} from './navbar/navbar.component';
import {FooterComponent} from './footer/footer.component';
import {NotificationComponent} from './notification/notification.component';
import {AppRoutingModule} from '../app-routing.module';
import {ToastsContainer} from "./toast/toast-container";
import {NgbToast} from "@ng-bootstrap/ng-bootstrap";
import {authInterceptorProviders} from "./interceptor.service";
import { NavbarBusinessComponent } from './navbar-business/navbar-business.component';

@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    NotificationComponent,
    ToastsContainer,
    NavbarBusinessComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    NgbToast,
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    ToastsContainer,
    NavbarBusinessComponent,
  ],
  providers: [
    authInterceptorProviders,
  ],
})
export class SharedModule {
}
