import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../auth/auth.service";

@Component({
  selector: 'app-navbar-business',
  templateUrl: './navbar-business.component.html',
  styleUrls: ['./navbar-business.component.scss']
})
export class NavbarBusinessComponent implements OnInit {

  isBusinessAdmin: boolean = false;
  isLoggedIn: boolean = false;

  logoSrc = "/assets/images/logo/logo.png"
  altText = "NamEvents Logo"

  constructor(private authService: AuthService) {
  }

  ngOnInit() {
    this.authService.getBusinessAdminStatus().subscribe(status => {
      this.isBusinessAdmin = status;
    });
    this.authService.getAuthStatus().subscribe(status => {
      this.isLoggedIn = status;
    });
  }

  logout() {
    this.authService.logout();
  }

}
